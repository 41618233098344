<template>
  <v-row class="px-3 mt-8" id="current_report"
    ><v-col cols="6">
      <h3>Calved</h3>
      <v-data-table
        :headers="headers"
        :items="calved"
        class="elevation-1 livestock_table"
        hide-default-footer
        ><template v-slot:[`item.tag.animal_tag_text`]="{ item }">
          <v-chip
            style="
              height: 24px;
              margin-bottom: -2px;
              margin-top: -3px;
              padding-top: 2px;
            "
            v-if="item.tag"
            :color="
              item.tag.animal_tag_color &&
              item.tag.animal_tag_color != 'UNDEFINED'
                ? 'oc_' +
                  item.tag.animal_tag_color.toLowerCase() +
                  ' darken-1'
                : 'grey darken-1'
            "
          >
            <span style="color: white; font-weight: 500">{{
              item.tag.animal_tag_text
            }}</span></v-chip
          ></template
        ></v-data-table
      ></v-col
    ><v-col cols="6">
      <h3>Not Calved</h3>
      <v-data-table
        :headers="headers"
        :items="not_calved"
        class="elevation-1 livestock_table"
        hide-default-footer
        ><template v-slot:[`item.tag.animal_tag_text`]="{ item }">
          <v-chip
            style="
              height: 24px;
              margin-bottom: -2px;
              margin-top: -3px;
              padding-top: 2px;
            "
            v-if="item.tag"
            :color="
              item.tag.animal_tag_color &&
              item.tag.animal_tag_color != 'UNDEFINED'
                ? 'oc_' +
                  item.tag.animal_tag_color.toLowerCase() +
                  ' darken-1'
                : 'grey darken-1'
            "
          >
            <span style="color: white; font-weight: 500">{{
              item.tag.animal_tag_text
            }}</span></v-chip
          ></template
        ></v-data-table
      ></v-col
    ></v-row
  >
</template>

<script>
export default {
  components: {},
  name: "CowsToCalve",
  props: ["site", "herd"],
  data: () => ({
    headers: [
      {
        text: "Tag",
        value: "tag.animal_tag_text",

        width: "140px",
      },
      {
        text: "RFID",
        value: "animal_rfid_current",

    
      },
    ],
  }),
  methods: {},
  created() {},
  mounted() {
    //this.grid_layout = parseInt(localStorage.getItem("preferedGrid"))
    //eventBus.$emit("change_layout", this.grid_layout);
  },
  watch: {},
  computed: {
    calved() {
      return this.$store.getters.getAnimals.filter(
        (a) => a.animal_dam_status == "CALF_AT_SIDE" && a.animal_classification == "COW" && (this.site != 'all' ? a.site_id == this.site : true) && (this.herd != 'all' ? a.herd_id == this.herd : true)
      );
    },
    not_calved() {
      return this.$store.getters.getAnimals.filter(
        (a) => a.animal_dam_status != "CALF_AT_SIDE" && a.animal_classification == "COW" && (this.site != 'all' ? a.site_id == this.site : true) && (this.herd != 'all' ? a.herd_id == this.herd : true)
      );
    },
  },
};
</script>
<style  lang="css">
</style>
