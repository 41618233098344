var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"px-3 mt-8",attrs:{"id":"current_report"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Calved")]),_c('v-data-table',{staticClass:"elevation-1 livestock_table",attrs:{"headers":_vm.headers,"items":_vm.calved,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.tag.animal_tag_text`,fn:function({ item }){return [(item.tag)?_c('v-chip',{staticStyle:{"height":"24px","margin-bottom":"-2px","margin-top":"-3px","padding-top":"2px"},attrs:{"color":item.tag.animal_tag_color &&
            item.tag.animal_tag_color != 'UNDEFINED'
              ? 'oc_' +
                item.tag.animal_tag_color.toLowerCase() +
                ' darken-1'
              : 'grey darken-1'}},[_c('span',{staticStyle:{"color":"white","font-weight":"500"}},[_vm._v(_vm._s(item.tag.animal_tag_text))])]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Not Calved")]),_c('v-data-table',{staticClass:"elevation-1 livestock_table",attrs:{"headers":_vm.headers,"items":_vm.not_calved,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.tag.animal_tag_text`,fn:function({ item }){return [(item.tag)?_c('v-chip',{staticStyle:{"height":"24px","margin-bottom":"-2px","margin-top":"-3px","padding-top":"2px"},attrs:{"color":item.tag.animal_tag_color &&
            item.tag.animal_tag_color != 'UNDEFINED'
              ? 'oc_' +
                item.tag.animal_tag_color.toLowerCase() +
                ' darken-1'
              : 'grey darken-1'}},[_c('span',{staticStyle:{"color":"white","font-weight":"500"}},[_vm._v(_vm._s(item.tag.animal_tag_text))])]):_vm._e()]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }